<template>
  <v-app>
    <v-main :style="this.$i18n.locale  === 'il' ?  {direction: 'rtl'}  :{}" :key="componentKey">
      <router-view/>
      <cFooter />
    </v-main>
  </v-app>
</template>

<script>
import cFooter from '@/components/cFooter.vue'
import timezones from '@/assets/data/countryCode.json'
import data from '@/assets/data/currencyCode.json'
import axios from 'axios'
import * as Sentry from '@sentry/browser'

export default {
  name: 'App',

  components: {
    cFooter
  },
  data () {
    return {
      componentKey: 0,
    }
  },
  async created () {
    document.title = `${this.$t('landingPage.metatitle') + ' ' + this.$store.state.Product.find(p => p.routerName === this.$route.name).name + ' ' + this.$t('landingPage.metatitleEnd')}`
    await this.$store.dispatch('SET_ARTICLE', this.$store.state.Product.find(p => p.routerName === this.$route.name).list[0].name)

    await this.$store.dispatch('SET_ADDRESS_BY_KEY', {
      key: 'country',
      value: this.getCountryCode()
    })
    await this.$store.dispatch('SET_ADDRESS_BY_KEY', {
      key: 'currency_code',
      value: 'EUR'
    })
    await this.$store.dispatch('GET_IP')
    if (this.$route.query.fbclid) {
      const fbp = this.$cookies.get('_fbp') || (this.$route.query.fbclid.includes('fb') ? this.$route.query.fbclid : `fb.1.${new Date().valueOf()}.${this.$route.query.fbclid}`)
      this.$store.commit('SET_FBCLID', fbp)
      if (this.$cookies.get('_addToCard') == null) {
        await this.addToCardFB()
        this.$cookies.set('_addToCard', true)
      }
    }

    if (this.$route.name === 'preview') {
      // https://overseee-api.herokuapp.com
      const { data } = await axios.get(process.env.VUE_APP_OVERSEE_URL + 'natives/funnels/staging/' + this.$route.query.routerName)
      const index = this.$store.state.Product.findIndex(s => s.routerName === 'preview')
      this.$store.state.Product[index] = { ...data, routerName: 'preview' }
      this.forceRerender()
    }
  },
  methods: {
    async addToCardFB () {
      try {
        const payload = {
          data: [
            {
              event_name: 'AddToCart',
              event_time: Math.floor(new Date().getTime() / 1000),
              action_source: 'website',
              event_source_url: process.env.VUE_APP_URL + this.$route.path.substring(1),
              user_data: {
                fbc: this.$store.state.Order.fbclid,
                client_ip_address: this.$store.state.Order.ip
              },
            }
          ],
          ...(
            process.env.VUE_APP_ENV !== 'PRODUCTION' ? { test_event_code: 'TEST34639' } : {}
          )
        }
        await axios(process.env.VUE_APP_FB_URL, { method: 'POST', data: { ...payload } })
      } catch (e) {
        console.log(e)
        Sentry.withScope(scope => {
          scope.setExtra('name', 'addToCard')
          scope.setExtra('response', JSON.stringify(e.response.data))
          Sentry.captureException(e)
        })
      }
    },
    forceRerender () {
      this.componentKey += 1
    },
    getCountryCode () {
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
      if (timezone === '' || !timezone) {
        return null
      }
      if (timezones[timezone].c && timezones[timezone].c.length) {
        return timezones[timezone].c[0]
      }
      return this.$i18n.locale === 'fr' ? 'FR' : 'GB'
    },
    getCurrency () {
      return data.countries.country.filter(c => (c.countryCode === this.getCountryCode())).length ? data.countries.country.filter(c => (c.countryCode === this.getCountryCode()))[0].currencyCode : null
    }
  }
}
</script>

<style lang="scss">
.container {
  @include media('sm-and-down') {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }
}

.no-text-transform {
  text-transform: initial !important;
}
</style>
