<!-- eslint-disable no-unused-vars -->
<template>
  <section class="form">
    <v-container>
      <v-form @submit.prevent="onSubmit" ref="form" id="submit" lazy-validation>
        <v-row>
          <v-col cols="12" md="7">
            <FormSectionLeft />
          </v-col>
          <v-col cols="12" md="5">
            <FormSectionRight />
          </v-col>
        </v-row>
      </v-form>
    </v-container>
    <v-snackbar v-if="isCardValid" :timeout="4000" v-model="isCardValid" bottom color="red" outlined right>
      {{ $t('validation.isCardValid') }}
    </v-snackbar>
  </section>
</template>

<script>
import FormSectionLeft from '@/components/Checkout/FormSectionColumns/FormSectionLeft.vue'
import FormSectionRight from '@/components/Checkout/FormSectionColumns/FormSectionRight.vue'
// import { loadRiskSdk } from '@/utils/risksdk'
import * as Sentry from '@sentry/browser'
import props from '@/components/CheckoutOrder/FormElements/propsGooglePay'
import Brevo from '@/utils/Brevo'

export default {
  components: {
    FormSectionLeft,
    FormSectionRight
  },
  props: {
    id: {
      type: String,
      required: false,
      default: 'google-pay-button'
    },
    options: {
      type: Object,
      required: false,
      default: function () {
        return props
      }
    }
  },
  data() {
    return {
      ...props,
      riskClient: null,
      riskDeviceSessionId: null,
      isCardValid: false,
      cardPaymentMethod: null,
      paymentsClient: null
    }
  },
  async mounted() {
    this.injectRiskScript()
    const varsSet = await this.assignVars()
    if (varsSet) this.injectGooglePayScript()
  },
  methods: {
    async ProcessCheckout(response) {
      try {
        console.log('ProcessCheckout')
        const responseCheckoutToken = await this.$store.dispatch('POST_TOKEN_GOOGLE_PAY', response)
        this.$store.commit('SET_TOKEN', responseCheckoutToken.data.token)
        const responseCheckout = await this.$store.dispatch('CHECKOUT_PAYMENTS_BY_CARD', { productName: this.$route.name, template: 'CheckoutOrder' })
        if (responseCheckout.data.id) {
          await this.$store.dispatch('SEND_CHECKOUT_ORDER', { id: responseCheckout.data.id, data: this.$store.state.Order })
          const response = await this.$store.dispatch('GET_PAYMENTS_BY_CHECKOUT', responseCheckout.data.id)
          const responseOrder = await this.$store.dispatch('GET_ORDER', response.id)
          this.$store.commit('SET_ORDER', { ...responseOrder.data[0].data[0], session_id: responseOrder.data[0].id_order, token_card: response.source.id })
        }
        if (responseCheckout.data.approved) {
          const { data: commandOverseeExist } = await this.$store.dispatch('COMMAND_OVERSEE_EXIST')
          if (!commandOverseeExist.length) {
            await this.$store.dispatch('SEND_COMMAND_OVERSEE')
            new Brevo('order_completed', this.$store, this.$route).track()
          }
          if (this.$store.state.Order.click_id && !commandOverseeExist.length) await this.$store.dispatch('SEND_TRACK')
          return await this.$router.push('/' + this.$i18n.locale + `/${this.$route.name}` + this.$store.getters.getRouterNameUpsellByProductRouterName(this.$route.name, 0))
        }
        if (responseCheckout.data._links.redirect.href) {
          window.location.href = responseCheckout.data._links.redirect.href
        } else {
          Sentry.captureMessage('No links redirect after Google Pay')
        }
      } catch (e) {
        Sentry.captureMessage('Error payment Google Pay')
      }
    },
    async assignVars() {
      Object.assign(this, this.options)
      return await this.$nextTick()
    },
    injectGooglePayScript() {
      if (!this.paymentsClient) {
        // load google pay script
        const googlePayScript = document.createElement('script')
        googlePayScript.setAttribute('src', 'https://pay.google.com/gp/p/js/pay.js')
        googlePayScript.setAttribute('async', true)
        googlePayScript.setAttribute('defer', true)
        googlePayScript.onload = () => this.onGooglePayLoaded()
        document.head.appendChild(googlePayScript)
      }
    },
    injectRiskScript() {
      console.log('VUE_APP_ENV ==>', process.env.VUE_APP_ENV)
      const riskUrl =
        process.env.VUE_APP_ENV === 'PRODUCTION' ? 'https://risk.checkout.com/cdn/risk/1.2/risk.js' : 'https://risk.sandbox.checkout.com/cdn/risk/1.2/risk.js'
      console.log('riskUrl ==>', riskUrl)
      try {
        const scriptID = 'risk-js'
        // const existingScript = document.getElementById(scriptID)
        if (!this.riskClient) {
          const script = document.createElement('script')
          script.id = scriptID
          script.src = riskUrl
          script.async = true
          script.onload = () => {
            if (window.Risk) {
              this.riskClient = window.Risk.init(process.env.VUE_APP_CHECKOUT_PUBLIC_KEY)
              console.log('Risk SDK loaded:', this.riskClient)
            } else {
              console.error('Risk SDK failed to load')
            }
          }
          script.onerror = () => console.error('Failed to load Risk SDK')
          document.head.appendChild(script)
        }
      } catch (error) {
        console.error('Risk.js failed to load:', error)
      }
    },

    initializeRiskClient() {
      /* eslint-disable */
      if (window.Risk && !this.riskClient) {
        try {
          this.riskClient = window.Risk.init(process.env.VUE_APP_CHECKOUT_PUBLIC_KEY)
          console.log('Risk SDK initialized successfully', this.riskClient)
        } catch (err) {
          console.error('Failed to initialize Risk SDK:', err)
        }
        console.log('after this.riskClient ==>', this.riskClient)
      } else {
        console.error('Risk SDK not found - ensure risk.js is loaded')
      }
    },
    async getRiskSessionId() {
      try {
        if (!this.riskClient) {
          throw new Error('Risk.js is not initialized yet.')
        }
        // Generate the session ID using Risk.js SDK
        this.riskDeviceSessionId = await this.riskClient.publishRiskData()
        console.log('Risk.js Generated Session ID:', this.riskDeviceSessionId)
        return this.riskDeviceSessionId
      } catch (error) {
        console.error('Risk.js Error generating session ID:', error)
      }
    },
    async initPaymentsVars() {
      this.cardPaymentMethod = Object.assign(
        {},
        this.baseCardPaymentMethod,
        {
          tokenizationSpecification: this.tokenizationSpecification
        }
      )
      return await this.$nextTick()
    },
    getGoogleIsReadyToPayRequest() {
      return Object.assign({}, this.baseRequest, {
        allowedPaymentMethods: [this.baseCardPaymentMethod]
      })
    },
    getGooglePaymentsClient() {
      if (this.paymentsClient === null) {
        // eslint-disable-next-line
        this.paymentsClient = new google.payments.api.PaymentsClient({
          environment: process.env.VUE_APP_ENV !== 'PRODUCTION' ? 'TEST' : this.environment
        })
      }
      return this.paymentsClient
    },
    addGooglePayButton() {
      this.googlePayButtonClick()
    },
    async onGooglePayLoaded() {
      const varsSet = await this.initPaymentsVars()
      if (varsSet) {
        const paymentsClient = this.getGooglePaymentsClient()
        paymentsClient
          .isReadyToPay(this.getGoogleIsReadyToPayRequest())
          .then(response => {
          })
          .catch(err => {
            // show error in developer console for debugging
            console.error(err)
          })
      }
    },
    getGooglePaymentDataRequest() {
      const paymentDataRequest = Object.assign({}, this.baseRequest)
      paymentDataRequest.allowedPaymentMethods = [
        this.cardPaymentMethod
      ]
      paymentDataRequest.transactionInfo = this.transactionInfo

      paymentDataRequest.transactionInfo.totalPrice = (this.$store.getters.getAmount / 100).toString()
      paymentDataRequest.transactionInfo.currencyCode = this.$store.getters.getArticle.currency.toUpperCase()
      paymentDataRequest.transactionInfo.countryCode = this.$store.state.Order.address.country.toUpperCase()

      paymentDataRequest.merchantInfo = {
        merchantId: this.merchantInfo.merchantId,
        merchantName: `${this.$store.state.Order.warranty ? this.$store.state.Order.article + ' + ' + this.$store.getters.getSelectedProduct.warranty.name : this.$store.state.Order.article} `
      }
      return paymentDataRequest
    },
    googlePayButtonClick() {
      // eslint-disable-next-line no-unreachable
      const paymentDataRequest = this.getGooglePaymentDataRequest()
      paymentDataRequest.transactionInfo = this.transactionInfo

      const paymentsClient = this.getGooglePaymentsClient()
      paymentsClient
        .loadPaymentData(paymentDataRequest)
        .then(paymentData => {
          this.ProcessCheckout(JSON.parse(paymentData.paymentMethodData.tokenizationData.token))
        })
        .catch(err => {
          console.error(err)
          if (err.statusCode === 'CANCELED') {
            Sentry.captureMessage('Error payment Google Pay - googlePayButtonClick')
          }
        })
    },
    async paymentByApplePay() {
      try {
        console.log('<= paymentByApplePay =>')
        // Define PaymentMethodData
        const paymentMethodData = [{
          supportedMethods: 'https://apple.com/apple-pay',
          data: {
            version: 3,
            merchantIdentifier: process.env.VUE_APP_MERCHANT_IDENTIFIER,
            merchantCapabilities: [
              'supports3DS'
            ],
            supportedNetworks: [
              'amex',
              'discover',
              'masterCard',
              'interac',
              'visa',
              'privateLabel'
            ],
            countryCode: this.$store.state.Order.address.country
          }
        }]
        // Define PaymentDetails
        const paymentDetails = {
          total: {
            label: process.env.VUE_APP_APPLICATION_NAME.toString().toLowerCase(),
            amount: {
              value: this.$store.getters.getAmount / 100,
              currency: this.$store.getters.getArticle.currency
            }
          }
        }
        // Define PaymentOptions
        const paymentOptions = {
          requestPayerName: false,
          requestBillingAddress: false,
          requestPayerEmail: false,
          requestPayerPhone: false,
          requestShipping: false
        }

        // Create PaymentRequest
        const request = new PaymentRequest(paymentMethodData, paymentDetails, paymentOptions)

        request.onmerchantvalidation = async event => {
          // Call your own server to request a new merchant session.
          // eslint-disable-next-line no-undef
          const merchantSessionPromise = await this.$store.dispatch('GET_APPLE_PAY_SESSION')
          event.complete(merchantSessionPromise.data)
        }

        request.onpaymentmethodchange = event => {
          if (event.methodDetails.type !== undefined) {
            // Define PaymentDetailsUpdate based on the selected payment method.
            // No updates or errors needed, pass an object with the same total.
            const paymentDetailsUpdate = {
              total: paymentDetails.total
            }
            event.updateWith(paymentDetailsUpdate)
          } else if (event.methodDetails.couponCode !== undefined) {
            // Define PaymentDetailsUpdate based on the coupon code.
            // eslint-disable-next-line no-undef
            const total = calculateTotal(event.methodDetails.couponCode)
            // eslint-disable-next-line no-undef
            const displayItems = calculateDisplayItem(event.methodDetails.couponCode)
            // eslint-disable-next-line no-undef
            const shippingOptions = calculateShippingOptions(event.methodDetails.couponCode)
            // eslint-disable-next-line no-undef
            const error = calculateError(event.methodDetails.couponCode)

            event.updateWith({
              total: total,
              displayItems: displayItems,
              shippingOptions: shippingOptions,
              modifiers: [
                {
                  data: {
                    additionalShippingMethods: shippingOptions
                  }
                }
              ],
              error: error
            })
          }
        }

        const response = await request.show()
        const status = 'success'
        await response.complete(status)
        const responseCheckoutToken = await this.$store.dispatch('POST_TOKEN_APPLE_PAY', response.details.token.paymentData)
        this.$store.commit('SET_TOKEN', responseCheckoutToken.data.token)




        // const responseCheckout = await this.$store.dispatch('CHECKOUT_PAYMENTS_BY_CARD', { productName: this.$route.name, template: 'checkout' })

        let deviceSessionId = null
        try {
          deviceSessionId = await this.riskClient.publishRiskData()
          console.log('riskClient ( deviceSessionId )=>', deviceSessionId)
        } catch (e) {
          console.error('Risk token generation failed (paymentByApplePay) => ', e)
        }

        const responseCheckout = await this.$store.dispatch('CHECKOUT_PAYMENTS_BY_CARD', {
          productName: this.$route.name,
          template: 'checkout',
          riskToken: deviceSessionId,
        })


        if (responseCheckout.data.id) {
          await this.$store.dispatch('SEND_CHECKOUT_ORDER', { id: responseCheckout.data.id, data: this.$store.state.Order })
          const response = await this.$store.dispatch('GET_PAYMENTS_BY_CHECKOUT', responseCheckout.data.id)
          const responseOrder = await this.$store.dispatch('GET_ORDER', response.id)
          this.$store.commit('SET_ORDER', { ...responseOrder.data[0].data[0], session_id: responseOrder.data[0].id_order, token_card: response.source.id })
        }
        if (responseCheckout.data.approved) {
          const { data: commandOverseeExist } = await this.$store.dispatch('COMMAND_OVERSEE_EXIST')
          if (!commandOverseeExist.length) {
            await this.$store.dispatch('SEND_COMMAND_OVERSEE')
            new Brevo('order_completed', this.$store, this.$route).track()
          }
          if (this.$store.state.Order.click_id && !commandOverseeExist.length) await this.$store.dispatch('SEND_TRACK')
          return await this.$router.push('/' + this.$i18n.locale + `/${this.$route.name}` + this.$store.getters.getRouterNameUpsellByProductRouterName(this.$route.name, 0))
        }
      } catch (e) {
        Sentry.captureMessage('Error payment Apple Pay')
        // Handle errors
      }
    },

    async submitPaypal() {
      try {
        const paypalResponse = await this.$store.dispatch('CHECKOUT_PAYPAL', { productName: this.$route.name, template: 'checkout' })
        await this.$store.dispatch('SEND_CHECKOUT_ORDER', { id: paypalResponse.id, data: this.$store.state.Order })
        window.location.href = paypalResponse.links.find(l => l.rel === 'payer-action').href
      } catch (e) {
        console.log('eeror paypal :', e)
      }
    },
    findChildById(parentComponent, childId) {
      if (parentComponent.$refs && parentComponent.$refs[childId]) {
        return parentComponent.$refs[childId] // Retourner le composant si trouvé
      }

      for (const childKey in parentComponent.$children) {
        const child = parentComponent.$children[childKey]
        const foundChild = this.findChildById(child, childId)

        if (foundChild) {
          return foundChild // Retourner le composant trouvé
        }
      }

      return null
    },
    async onSubmit() {

      try {
        const isValid = this.$refs.form.validate()
        const email = this.findChildById(this.$refs.form, 'email')
        if (!isValid || email.errorMessages) {
          return
        }
        this.$emit('loading', true)

        const paymentMethod = this.$store.state.Order.payment_method
        const stripeCardEmpty = (selector) => document.querySelector(selector).classList.contains('StripeElement--empty')

        if (paymentMethod === 'stripeCard') {
          if (stripeCardEmpty('#card-number') || stripeCardEmpty('#card-expiry') || stripeCardEmpty('#card-cvc')) {
            this.isCardValid = true
            this.$emit('loading', false)
            return
          }
          return this.$root.$emit('stripeCardCheckout')
        }

        if (paymentMethod === 'paypal') {
          await this.submitPaypal()
        }

        if (paymentMethod === 'googlePay') {
          await this.googlePayButtonClick()
        }

        if (paymentMethod === 'applePay') {
          await this.paymentByApplePay()
        }

        if (paymentMethod === 'card') {
          if (!Frames.isCardValid()) {
            this.isCardValid = true
            this.$emit('loading', false)
            return
          }
          try {
            /* global Frames */
            await Frames.submitCard()
          } catch (e) {
            this.$emit('loading', false)
            Sentry.captureMessage('Frames SubmitCard')
          }

          // console.log('<= paymentMethod card =>')
          // const responseCheckout = await this.$store.dispatch('CHECKOUT_PAYMENTS_BY_CARD', {
          //   productName: this.$route.name,
          //   template: 'checkout'
          // })

          console.log('onSubmit ( riskClient )=>', this.riskClient)
          let deviceSessionId = null
          try {
            deviceSessionId = await this.riskClient.publishRiskData()
            console.log('riskClient ( deviceSessionId )=>', deviceSessionId)
          } catch (e) {
            console.error('Risk token generation failed (onSubmit) =>', e)
          }

          const checkoutData = {
            productName: this.$route.name,
            template: 'checkout',
            riskToken: deviceSessionId,
          }
          console.log('checkoutData =>', checkoutData)
          const responseCheckout = await this.$store.dispatch('CHECKOUT_PAYMENTS_BY_CARD', checkoutData)

          if (responseCheckout.data.id) {
            await this.$store.dispatch('SEND_CHECKOUT_ORDER', {
              id: responseCheckout.data.id,
              data: this.$store.state.Order
            })
          }
          if (responseCheckout.data.status === 'Pending' && responseCheckout.data['3ds'].upgrade_reason === 'sca_retry') {
            window.location.href = responseCheckout.data._links.redirect.href
          }

          if (responseCheckout.data.id && responseCheckout.data.approved) {
            try {
              const resultCheckout = await this.$store.dispatch('GET_PAYMENTS_BY_CHECKOUT', responseCheckout.data.id)
              const responseOrder = await this.$store.dispatch('GET_ORDER', resultCheckout.id)
              this.$store.commit('SET_ORDER', {
                ...responseOrder.data[0].data[0],
                session_id: responseOrder.data[0].id_order,
                token_card: responseCheckout.data.source.id
              })
              const { data: commandOverseeExist } = await this.$store.dispatch('COMMAND_OVERSEE_EXIST')
              if (!commandOverseeExist.length) {
                await this.$store.dispatch('SEND_COMMAND_OVERSEE')
                new Brevo('order_completed', this.$store, this.$route).track()
              }
              if (this.$store.state.Order.click_id && !commandOverseeExist.length) await this.$store.dispatch('SEND_TRACK')
              if (responseCheckout.data.source.type === 'sofort') return await this.$router.push('/' + this.$i18n.locale + `/${this.$route.name}/thank`)
              this.$store.commit('SET_FINISH', false)
              this.$emit('loading', false)

              return await this.$router.push('/' + this.$i18n.locale + `/${this.$route.name}` + this.$store.getters.getRouterNameUpsellByProductRouterName(this.$route.name, 0))
            } catch (e) {
              console.log(e)
              Sentry.withScope(scope => {
                scope.setExtra('name', 'CHECKOUT')
                scope.setExtra('state', this.$store.state.Order)
                scope.setExtra('response', JSON.stringify(e.response.data))
                Sentry.captureException(e)
              })
              try {
                await this.$store.dispatch('SEND_MAIL_3D_SECURE_FAILED', this.getStatusUserIfFailed())
              } catch (e) {
                Sentry.withScope(scope => {
                  scope.setExtra('state', JSON.stringify(responseCheckout))
                  scope.setExtra('response', JSON.stringify(responseCheckout.data))
                  Sentry.captureMessage('SEND_MAIL_3D_SECURE_FAILED')
                })
              }
              this.$emit('loading', false)
              return await this.$router.push('/' + this.$i18n.locale + `/${this.$route.name}/error/`)
            }
          }

          if (responseCheckout.data._links.redirect?.href) {
            window.location.href = responseCheckout.data._links.redirect.href
          } else {
            this.$emit('loading', false)
            Sentry.withScope(scope => {
              scope.setExtra('state', JSON.stringify(responseCheckout))
              scope.setExtra('response', JSON.stringify(responseCheckout.data))
              Sentry.captureMessage('No links redirect after CHECKOUT_PAYMENTS_BY_CARD')
            })
            return await this.$router.push('/' + this.$i18n.locale + `/${this.$route.name}/error/`)
          }
        }
      } catch (error) {
        Sentry.withScope(scope => {
          scope.setExtra('state', JSON.stringify(this.$store.state))
          scope.setExtra('error', JSON.stringify(error))
          Sentry.captureMessage('SUBMIT ERROR')
        })
        this.$emit('loading', false)
        await this.$router.push('/' + this.$i18n.locale + `/${this.$route.name}/error/`)
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
