import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import '@/assets/scss/global.scss'
import i18n from '@/plugins/i18n'
import VueCookies from 'vue-cookies'
import UserTracker from '@/utils/userTrack'
// eslint-disable-next-line no-new
// import { RiskPlugin } from './plugins/risk'
// import RiskPlugin from './plugins/RiskPlugin'

Vue.config.productionTip = false
Vue.use(VueCookies, {})

// Vue.use(RiskPlugin, {
//   publicKey: process.env.VUE_APP_CHECKOUT_PUBLIC_KEY,
//   environment: process.env.NODE_ENV === 'production' ? 'production' : 'sandbox'
// })

// eslint-disable-next-line no-new
new UserTracker(router, store)
new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
